import { useEffect } from 'react';
import { useLatest } from 'react-use';

export function HubspotMeetingsForm(props: {
  title: string;
  src: string;
  className?: string;
  onLoad?: () => void;
  onSubmitted?: () => void;
}) {
  const onSubmitted = useLatest(props.onSubmitted);

  useEffect(() => {
    const handleEvent = (event: MessageEvent) => {
      if (event.origin !== 'https://meetings.hubspot.com') {
        return;
      }
      if (event.data instanceof Object && event.data.meetingBookSucceeded) {
        if (onSubmitted.current) {
          onSubmitted.current();
        }
      }
    };

    window.addEventListener('message', handleEvent);
    return () => {
      window.removeEventListener('message', handleEvent);
    };
  }, [onSubmitted]);

  return (
    <iframe
      title={props.title}
      src={props.src}
      className={props.className}
      onLoad={props.onLoad}
      sandbox='allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox'
    />
  );
}
